import React from 'react'
import logo from '../../assets/img/logo4.png'
import './style.css'
import AboutCoindata from '../sectionsHome/AboutCoindata'
import SocialMedia from '../sectionsHome/SocialMedia'
import Detalisabout from '../sectionsHome/Detalisabout'
import BurnedWallet from '../sectionsHome/BurnedWallet'
import Footer from '../../components/footer/Footer'
import Box from './Box'
import Memeconatiner from  '../sectionsHome/Memeconatiner'

function Home() {


  return (
    <>
      <div className='section'>
        <div class="container">
         
        <div className='logo-content logo-content-top'>
              <img src={logo} />
            </div>

        </div>

        <div class="container mrginTp">
          <AboutCoindata />
        </div>
        <div class="container mrginTp">
          <SocialMedia />
        </div>
        <div class="container mrginTp">
          <Detalisabout />
        </div>
        <div class="container mrginTp">
          <BurnedWallet />
        </div>

        

        <div class="container mrginTp">
          <Memeconatiner />
        </div>

        <div class="container mrginTp">
          <Box />
        </div>


     

        <div class="container-fluid mrginTp" style={{padding:"0"}}>
<Footer />
</div>
      </div>

    </>
  )
}

export default Home
