import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/container/pages/Home';
import Navigation from '../src/components/NavigationBar/Navigation'
import './App.css'

import { ThemeContext } from '../src/themebutton/theme-context'
function App() {
 const { theme, toggle, dark } = React.useContext(ThemeContext)
  return (
     <div className="App" style={{ backgroundColor: theme.backgroundColor, color: theme.color }} >
    
        {/* <button className='btn-content'
          type="button"
          onClick={toggle}
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.color,
            outline: 'none'
          }}
          data-testid="toggle-theme-btn"
        >
          {!dark ? 
               <span><img src={darkt} alt="dark"/> </span>
          : 
          <span> <img className='dthem' src={light} alt="Light"/> </span>
          }
        </button> */}
      <Navigation />

        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>

    

    </div>
  )
}

export default App
