import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


import { ThemeProvider } from '../src/themebutton/theme-context'
const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
  <ThemeProvider>
<App />
</ThemeProvider>
  </React.StrictMode>,
    rootElement
);

