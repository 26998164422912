import React from 'react'


function Presaleaddress() {


  return (
    <>
         
         <h2>Presale Address</h2>
         <div className='address-conainer'>
        
       <div className='copy-text-box'>
        <div className='input-text-in'>
        <span> </span>
        </div>
        <div className='input-text-in'>
        <button className='button-to-copy'>Copy</button>
        </div>
       </div>
       
      </div>

    </>
  )
}

export default Presaleaddress
