import React from 'react'
import creature from '../../assets/img/creature1.png'
function SocialMedia() {


  return (
    <>
      <div class="coindata-smedia">
        <div class="coindata-creature">
     <img src={creature} alt='img' />
        </div>
      </div>

    </>
  )
}

export default SocialMedia
