import React from 'react'
import { ThemeContext } from '../../themebutton/theme-context'
import { HeroTexts } from "../particles/Data";
import Presaleaddress from "../address/PresaleAddress";
function AboutCoindata() {
    const { theme } = React.useContext(ThemeContext)
    return (
        <>

<div class="coindata-container4">
                <h1>Zome is an Art, a Meme,<br /> a hub of Memes and NFT</h1>
                </div>

            <div class="coindata-container">
               
               

<Presaleaddress />



                <div className='box-fordatadx'>
                    
{/* <div class="card1 human-resources">
     <div class="overlay3"></div>
  <div class="circle">
     
    
csdss

  </div>
  <p>Human Resources</p>
</div> */}
                {/* <div class="feed-in">
  <ul>
    <li><i class="fa fa-facebook" aria-hidden="true"></i></li>
    <li><i class="fa fa-twitter" aria-hidden="true"></i></li>
    <li><i class="fa fa-google-plus" aria-hidden="true"></i></li>
  </ul>
</div> */}

                    <div className='inner-boxdx'>
                   
                        {HeroTexts.map((hero, index) => (
                            // <div className='boxedx' style={{ backgroundColor: theme.backgroundColor, color: theme.color }} >
                            <div class="card1 human-resources">
                                  <div class="overlay3"></div>
                                  <div class="circle">
                                 <img src={hero.icon} />
                              </div>
                               <p>{hero.heads}</p>
                            </div>
                        ))}
        
                    </div>
                </div>

            </div>




        </>
    )
}

export default AboutCoindata
