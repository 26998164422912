import React, { useState, useEffect } from 'react'
import './style.css'
import { ThemeContext } from '../../themebutton/theme-context'
//import styled from 'styled-components';

// const StyledComponent = styled.div`
//   //background-color: ${(props) => props.color};
//   //font-size: 10vmax;

//   /* Media query for screens smaller than 768px */
//   ${props =>
//     props.isMobile &&
//     css`
//       @media (max-width: 500px) {
//         font-size: 11vmax;
//       }
//     `}
// `;



function Navigation() {

  const isMobile = window.innerWidth >500;


  const { theme, toggle, dark } = React.useContext(ThemeContext)
  const [className, setClassName] = useState('');
  const [classNameto, setClassNameto] = useState('');
  const [positions, setPositions] = useState("relative");
  const [navSize, setnavSize] = useState("11vmax");
  const [navSize2, setnavSize2] = useState("9vmax");
  const [navSize1, setnavSize1] = useState("10vmax");
 // const [lSpacing, setLSpacing] = useState("-11px");
 
 // const [heightd, setHeightd] = useState("255px");


  const listenScrollEvent = () => {
    window.scrollY > 10 ? setPositions("fixed") : setPositions("relative");
    window.scrollY > 10 ? setnavSize("6vmax") : setnavSize("11vmax");
    window.scrollY > 10 ? setnavSize2("7vmax") : setnavSize2("9vmax");
    window.scrollY > 10 ? setnavSize1("8vmax") : setnavSize1("10vmax");
    //window.scrollY > 10 ? setHeightd("255px") : setHeightd("175x");
   // window.scrollY > 10 ? setLSpacing("3px") : setLSpacing("-11px");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
        window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <>
<div className="navigation-box" style={{backgroundColor: theme.backgroundColor, position: positions,transition: "all 1s" }} >
        <div className='menue-box'>
        <span style={{fontSize: isMobile ? navSize : "6vmax",  transition: "all 1s"}}>Zoo of Memes</span>
          <h1 data-shadow='Zome' style={{fontSize:navSize, transition: "all 1s"  }}>
            <span style={{fontSize:navSize1}}>$</span>
            <span style={{fontSize:navSize2, transition: "all 1s"  }}>Zome</span>
            </h1>
         
        
          
        </div>
        {/* <div className='menue-box'>
          <button className='btn-content'
            type="button"
            onClick={toggle}
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              outline: 'none'
            }}
            data-testid="toggle-theme-btn"
          >
            {!dark ?
              <span><img src={darkt} alt="dark" /> </span>
              :
              <span> <img className='dthem' src={light} alt="Light" /> </span>
            }
          </button>




          <div className={`overlay ${className}`} id="overlay">
            <nav class="overlay-menu">
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Work</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </nav>
          </div>
          <div className={`button_container ${classNameto}`} onClick={toggleDisplay}>
            <div className='bigBtn'>
            ═
            </div>
            <div className='bigBtn2'>
            ⊗
            </div>
          </div>

        

        </div> */}

      </div>

    </>
  )
}

export default Navigation
