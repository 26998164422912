
import sd1 from '../../assets/img/twitter.png'
import sd2 from '../../assets/img/reddit.png'
import sd3 from '../../assets/img/telegram.png'
import sd4 from '../../assets/img/discord.png'
import sd5 from '../../assets/img/tiktok.png'
import sd6 from '../../assets/img/gecko.png'
import sd7 from '../../assets/img/mrkap.png'

import sdk1 from '../../assets/img/k2.png'
import sdk2 from '../../assets/img/telegramA1.png'
import sdk3 from '../../assets/img/dextools.png'

export const HeroTexts = [
    {
        icon: `${sdk1}`,
        heads: "Twitter",
     
    },
    {
        icon: `${sdk2}`,
        heads: "Telegram",
     
    },
    {
        icon: `${sdk3}`,
        heads: "Dextools",
    }
]


export const socialMd = [
    {
        icon: `${sd1}`,
        sname: "twitter",
     
    },
    {
        icon: `${sd2}`,
        sname: "Reddit",
     
    },
    {
        icon: `${sd3}`,
        sname: "Telegram",
    },
    {
        icon: `${sd4}`,
        sname: "Discord",
    },
    {
        icon: `${sd5}`,
        sname: "Tiktok",
    },
    {
        icon: `${sd6}`,
        sname: "CoinGecko",
    },
    {
        icon: `${sd7}`,
        sname: "CoinMarketCap",
    }
]



export const AboutTexts = [
    {
        heads1: "Step right into the Zoo of Memes, where the coolest memes in town strut their stuff, living it up in the wild world of Zomeland. Forget boring old internet images – here, memes are like rock stars, kicking back with their meme buddies and running the show like pros.",
    },
    {
        heads2: "In Zomeland, we've got our own rules, and our meme homies make sure everything stays legit. Our economy? Cryptocurrencies, baby. We're talking next-level transactions and economic wizardry that puts Wall Street to shame.",
    },
    {
        heads3: "At the epicenter of this meme madness is the MEME Lord, chosen through the fiery ritual of burning $ZOME tokens – a true badge of honor in our meme kingdom. With their savvy leadership, our memes are living their best lives.",
    },
    {
        heads4: "But Zomeland isn't just about business. We've got everything from epic workouts to chill pool parties, and our news game is on point. Plus, we've got courts and regulators keeping things real, because even memes need justice.",
    },
    {
        heads5: "And let's not forget the social scene – meme weddings, new meme babies, you name it. It's all fueled by $ZOME, the lifeblood of our decentralized utopia powered by BASE Blockchain. Yeah, we're high-tech like that.",
    },
    {
        heads6: "So, welcome to Zomeland, where memes rule, dreams come true, and the party never stops. Get ready to laugh, create, and join the wildest community on the planet.",
    }
]







export const TestimonialTexts = {
    firstText: "our testimonials",
    secondText: "What Clients Say",
    feedBacks: [
        {
            text: "I've been a member for six months, and the gym's supportive community and knowledgeable trainers have helped me surpass my fitness goals!",
            person: "Darrell Murray",
            type: "Client"
        },
        {
            text: "The Yoga classes have transformed my life; I feel more centered, strong, and peaceful every time I step on the mat.",
            person: "Derrick Rodriquez",
            type: "Client"
        },
        {
            text: "Attending the Special Class has been a game-changer! It's intense, but the results are incredible. Highly recommended!",
            person: "Collins Thompson",
            type: "Client"
        }
    ],
}
