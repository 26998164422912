import React from 'react'
import { ThemeContext } from '../../themebutton/theme-context'
import logo3 from '../../assets/img/reddit.png'
import { HeroTexts } from "../particles/Data";

function BurnedWallet() {
    const { theme } = React.useContext(ThemeContext)
    return (
        <>
            <div class="coindata-container">
        
            <h5 class="toKamno">BURNED $Zome</h5>

                <div className='box-snek'>
                    <div className='burned-snek'>
                        <span>0</span>
                    </div>
        <button className='Burn-wallet'>Burn wallet</button>
                   
                </div>

            </div>




        </>
    )
}

export default BurnedWallet
