import React from 'react'
import { AboutTexts } from "../particles/Data";
import { ThemeContext } from '../../themebutton/theme-context'
function Detalisabout() {
  const { theme } = React.useContext(ThemeContext)

  return (
    <>
      <div className='section'>
        <div class="container">
          <div class="about-section" style={{ backgroundColor: theme.backgroundColor, color: theme.color }} >
            <div className='aboutsnek'>
              <h3>About Zome</h3>
          
                {AboutTexts.map((hero, index) => (
                  <>
                    <p>{hero.heads1}{hero.heads2}{hero.heads3}{hero.heads4}{hero.heads5}{hero.heads6}</p>
                 
                    
                  </>
                ))}
          
              <h4>In Zome we trust</h4>
            </div>

          </div>
        </div>


      </div>

    </>
  )
}

export default Detalisabout
