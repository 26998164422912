import React, { useEffect, useState } from 'react';
import { HeroTexts } from './Data';

function Box() {
    // const [chartData, setChartData] = useState([]);

    // useEffect(() => {
    //   // Fetch data from API
    //   fetch(HeroTexts)
    //     .then(response => response.json())
    //     .then(data => setChartData(data))
    //     .catch(error => console.error('Error fetching data:', error));
    // }, []);

    // if (!chartData) {
    //   return <div>Loading...</div>;
    // }

    // const styles = {
    //   '--first': chartData.first,
    //   '--second': chartData.second,
    //   '--third': chartData.third,
    //   '--fourth': chartData.fourth,
    //   '--fifth': chartData.fifth,
    //   '--donut-spacing': chartData.donutSpacing
    // };

    const styles = {
        '--first': 0.70,
        '--second': 0.16,
        '--third': 0.10,
        '--fourth': 0.02,
        '--fifth': 0.02,
        '--donut-spacing': 2
    };
    return (
        <>
         <h1 className='toKamno'>Zoonomics</h1>
      
            <div className='donut-conainer'>
   

                {/* <div class="donut" style={{'--first': .70, '--second': .16, '--third': .10, '--fourth': .02, '--fifth': .02, '--donut-spacing': 2}}> */}
              <div className='donut-cornd'>
              <div className="donut" style={styles}>
                    {HeroTexts.map((hero, index) => (
                        <div className={`donut__slice donut__slice__${hero.clname}`}>
                        </div>
                    ))}
                </div>
                <div class="donut__label">
    <div class="donut__label__heading">
    <button className='tsupply'>Total Supply</button>
    </div>
    <div class="donut__label__sub">
   <span className='tsupply-snek'>10,000,000,000</span> 
    </div>
  </div>
              </div>
             

                 <div className='donut-cornd'>
                 <div className='donut-legends-conainer'>
                    <div className='donut-legends'>

                        {HeroTexts.map((hero, index) => (
                            <div className="popcontent">
                                <div className='sd1' style={{ backgroundColor: `${hero.colors}` }}>
                                    <div className='widk1'>{`${hero.Numbers}%`}</div>
                                </div>
                                <div className='sd2'>
                                <div className='widk2'>{`${hero.hed1}`}</div>
                              
                                     
                                     </div>
                            </div>
                        ))}

                    </div>
                </div>
                 </div>
                

            </div>
        </>



    );
}
export default Box