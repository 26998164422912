import React from 'react'
import logo from '../../assets/img/logo4.png'
import twitter from '../../assets/img/g2.png'
import teleg from '../../assets/img/g1.png'
import zomebtn from '../../assets/img/creature4.png'


import './style.css'


function Footer() {


	return (
		<>
			<footer>
				<div class="footer-wrap">
					{/* <div class="container first_class">
	
	</div> */}
					<div class="second_class">
						<div class="container second_class_bdr">
							<div class="row">
								<div class="col-md-4 col-sm-12">
									<div className='menue-box2'>
										<h6>$Zome</h6>
									</div>

								</div>
								<div class="col-md-8 col-sm-12">
								
									<div className='join-community-container'>
									<div className='join-community'>
									<h6>JOIN THE $ZOME COMMUNITY</h6>
										</div>
										<div className='join-community'>
										<span style={{textTransform:'lowercase'}}>info@zome.wtf</span> 
										</div>
										<div className='join-community'>
										<div className='join-community1'>
										 <div className='join-com'>
										 <div class="card3 human-resources">
											<div class="circle3">
													<img src={twitter} alt='twitter' />
													</div></div>
										 </div>
										 <div className='join-com'>
										 <div class="card3 human-resources">
											
											<div class="circle3">
											<img src={teleg} alt='twitter' />
											</div>
											</div>
										 </div>
										 </div>
										</div>
										<div className='join-community'>
										<button class="join-community-button">
											<span className='crone'>
												<img src={zomebtn} alt='zome'/>
											</span>
											Buy $ZOME</button>
										</div>
									</div>
									
								</div>

							</div>

						</div>
					</div>

					{/* <div class="row">

						<div class="container-fluid">
							<div class="copyright"> Copyright ... | All Rights Reserved by ....</div>
						</div>

					</div> */}
				</div>

			</footer>

		</>
	)
}

export default Footer
