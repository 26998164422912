import React from 'react'
import background1 from '../../assets/img/background1.png'


function Memeconatiner() {

 
  return (
    <>
  
        <div class="coindata-smedia">
          <div className='backimage-container'>
            <img src={background1} alt="zome" />
          </div>
       
</div>
    </>
  )
}

export default Memeconatiner
