

export const HeroTexts = [
  {
    firsta:".70",
      Numbers: "50",
      hed1:"Presale holders",
      colors:"#ff6838",
      clname:"first"
  },
  {
    seconda:".16",
      Numbers: "40",
      hed1:"Initial liquidity",
      colors:"#ffc820",
      clname:"second"
  }
  ,
  {
    thirda:".10",
      Numbers: "2",
      hed1:"Marketing",
      colors:"#72a934",
      clname:"third"
  }
  ,
  {
    fourtha:'.02',
      Numbers: "5",
      hed1:"Utility",
      colors:"#258bba",
      clname:"fourth"
  }
  ,
  {
    fiftha:'.02',
      Numbers: "3",
      hed1:"Reserve",
      colors:"#4e0ca5",
      clname:"fifth"
  }

]
